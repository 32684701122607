<template src='./initiate.html'>
</template>
<style scoped src="./initiate.css"></style>
<script>
import { mapGetters } from 'vuex' 
import TransactionService from '@/services/TransactionService.vue'
import TransationConstants from '@/constants/TransactionConstants.vue'
import Utils from '@/utils/utils'
import Loading from 'vue-loading-overlay'
import { required, minValue } from 'vuelidate/lib/validators'
import applicationConstants from '@/constants/AppConstants'
import getRecipients from '@/components/modal/getRecipient/recipient.vue'
import AccountService from '@/services/AccountService'
import modal from '@/components/modal/notification/modal'
export default {
    data () {
        return {
            etranfer : {},
            requestAmt : '',
            transationPursposeList : TransationConstants.transactionPurpose ,
            transationPurspose : ''  ,
            promoCode : '',
            exchangeRate : '',
            fee : '',
            sourceAmt : '',
            destAmt : '',
            loaderVisible : false,
            errorMessages:applicationConstants.formsMessages,
            showGetRecipients : true,
            AccountWhitleListed : false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            }
        }
    },
    computed : {
        ...mapGetters(['getRemittanceOptions2','getUseremail']),
        customerName () {
            return this.etranfer.FirstName + ' ' + this.etranfer.LastName
        }
    },
    methods : {
        async calculateTransationFee () {
            this.$v.requestAmt.$touch()
            const clearFields = () => {
                this.exchangeRate = null
                this.fee = null
                this.sourceAmt = null
                this.destAmt = null
            }
            if (+this.requestAmt < 50  && !(this.AccountWhitleListed)) { 
                this.requestAmt = null
                clearFields()
                this.showAlert(null, 'We require $50 CAD as the minimum transfer amount for security purposes. Please enter an amount of $50 or above.')
                return
            }
            if(this.$v.requestAmt.$invalid){
                clearFields()
                return
            }
            if(!this.promoCode.includes('-') && this.promoCode.length > 0) {
                this.showAlert(null, 'Invalid Code format')
                return
            }
            this.loaderVisible = true
            let response =  await Utils.methods.calculateTransationFee({
                DestCountry : 'India',
                DestCurrency: this.etranfer.remittancecurreny,
                OriginCountry : 'Canada',
                OriginCurrency : 'CAD',
                RemittanceGatewayId : this.etranfer.remittancegatewayId,
                SendAmountOrigin: this.requestAmt
            },this.promoCode,this.etranfer.senderemail)  
            if(!response.Error || response.ErrorCode === '2000') {
                if(response.ErrorCode === '2000') {
                    this.alertObject = response.Error
                    this.$bvModal.show(this.$refs.childModal.id)
                }
                this.exchangeRate =  response.ExchangeRate
                this.fee = response.Fee
                this.sourceAmt = response.SourceAmount
                this.destAmt = response.DestAmount
            }else {
                this.alertObject = response.Error
                this.$bvModal.show(this.$refs.childModal.id)
            }
           this.loaderVisible = false
        },
        onSubmit () {
            this.$v.$touch()
            if(this.$v.$invalid){
                return
            }
            if(!this.promoCode.includes('-') && this.promoCode.length > 0){
                this.showAlert(null, 'invalid code format')
                return
            }
            this.loaderVisible = true
            let promoCode = this.promoCode.split('-')
            TransactionService.methods.initiateEtransfer({
                'TransactionTotal' : this.sourceAmt,
                'CustomerEmail'    : this.etranfer.senderemail,
                'PromoCodeData'    : {
                    'Amount'            : this.requestAmt,
                    'Code'              : promoCode[0],
                    'Issue'             : promoCode[1],
                    'RemittanceGateway' : this.etranfer.remittancegatewayId           
                },
                'SendMoneyRequest' : {
                    'OriginCurrency'    : 'CAD',
                    'Purpose'           : this.transationPurspose,
                    'RecipientId'       : this.etranfer.Id,
                    'XferAmount'        : this.requestAmt,
                    'CustomerEmail'     : this.etranfer.senderemail,
                    'RequestedDestinationAmount'  : this.destAmt
                }
            })
            .then(res => {
                this.loaderVisible = false
                this.$router.push({
                    name : 'ReviewETransfer',
                    params: {
                        email : this.etranfer.senderemail,
                        Transactionid : res.csEtransferInitResponse.TransactionId._text,
                        type : 'etransfer'
                    }
                })
            })
            .catch(err => {
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
                this.loaderVisible = false
            })
        },
        async getRecipientsData(recipient) {
            this.loaderVisible = true
            this.clearFields()
            this.etranfer = recipient
            let remittanceoption = this.getRemittanceOptions2.find(s=> s.RemittanceOptionId === recipient.RemittanceGatewayId)
            this.etranfer.remittancegateway = remittanceoption.Name
            this.etranfer.remittancecurreny = remittanceoption.Currency
            this.etranfer.remittancegatewayId =  remittanceoption.RemittanceOptionId
            this.etranfer.senderemail = this.getUseremail
            await AccountService.methods.GetAccountBalance(this.getUseremail)
            .then (res => {
                try {
                    this.etranfer.pcmBalance = parseFloat(res.csAccountBalanceResponse.Balance._text).toFixed(2)
                } catch (error) {
                    //                 
                }
            })
            await AccountService.methods.GetAccountDetails(this.getUseremail)
            .then( res => {
                this.AccountWhitleListed = (res.csGetUserProfileResponse.WhitelistedForLimits._text).toLowerCase() === 'true'
            })
            this.showGetRecipients = false
            this.loaderVisible = false
        },
        clearFields() {
            this.requestAmt = '',
            this.transationPurspose = ''  ,
            this.promoCode = '',
            this.exchangeRate = '',
            this.fee = '',
            this.sourceAmt = '',
            this.destAmt = '',
            this.$v.$reset()
        },
        showAlert(code, description) {
            this.alertObject.errorCode = code
            this.alertObject.errorDescription = description
            this.alertObject.errorDetails = []
            this.$bvModal.show(this.$refs.childModal.id)
        }
    },
    components : {
        Loading,getRecipients, modal
    },
    validations : {
        requestAmt : {
            required,
            minValue : minValue(0.0001)
        },
        transationPurspose : {
            required
        }
    }
}
</script>